import React from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";

function Footer() {

  return (
    <Segment
        inverted
        vertical
        style={{
          margin: "5em 0em 0em",
          padding: "5em 0em",
          backgroundColor: "white",
          width: "-webkit-fill-available",
          position: "absßolute",
          left: "0px",
        }}
      >
        <Divider inverted section />
        <Container textAlign="center">
          <Grid divided inverted stackable>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Contact US"
                style={{
                  color: "black",
                }}
              />
              <List link inverted>
                {/* <List.Item as='a' href="https://academicresources.clas.ufl.edu/tutoring/">Tutoring</List.Item> */}
                <List.Item
                  as="a"
                  style={{
                    color: "black",
                  }}
                >
                  Phone: 305-575-1835
                </List.Item>
                <List.Item
                  as="a"
                  style={{
                    color: "black",
                  }}
                >
                  Email: arangospartyrental@gmail.com
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Socials"
                style={{
                  color: "black",
                }}
              />
              <List link inverted>
                <List.Item
                  as="a"
                  href="https://www.facebook.com/profile.php?id=100089895841983&mibextid=LQQJ4d"
                >
                  <Icon
                    name="facebook"
                    size="large"
                    style={{
                      color: "black",
                    }}
                  />
                </List.Item>
                <List.Item
                  as="a"
                  href="https://www.instagram.com/arangospartyrental/"
                >
                  <Icon
                    name="instagram"
                    size="large"
                    style={{
                      color: "black",
                    }}
                  />
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header
                inverted
                as="h4"
                content="Hours"
                style={{
                  color: "black",
                }}
              />
              <List link inverted>
                <List.Item
                  as="a"
                  style={{
                    color: "black",
                  }}
                >
                  Monday-Friday: 7AM-5PM
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header
                inverted
                as="h4"
                content="Arangos Party Rental"
                style={{
                  color: "black",
                }}
              />
              <p style={{
                  color: "black",
                }}>          
                    Transform your party by renting our products! 
                    Make us your party hub!
                    </p>
            </Grid.Column>
          </Grid>

          <Divider inverted section />
          <Image
            centered
            size="small"
            src="/logo.png"
            style={{
              color: "black",
            }}
          />
          {/* <List
            horizontal
            inverted
            divided
            link
            size="small"
            style={{
              color: "black",
            }}
          > */}
            {/* <List.Item
              as="a"
              href="#"
              style={{
                color: "black",
              }}
            >
              Site Map
            </List.Item>
            <List.Item
              as="a"
              href="#"
              style={{
                color: "black",
              }}
            >
              Contact Us
            </List.Item>
            <List.Item
              as="a"
              href="#"
              style={{
                color: "black",
              }}
            >
              Terms and Conditions
            </List.Item>
            <List.Item
              as="a"
              href="#"
              style={{
                color: "black",
              }}
            >
              Privacy Policy
            </List.Item>
          </List> */}
        </Container>
      </Segment>
  );
}

export default Footer;
