import React, { Component } from "react";
import {
  Container,
  Icon,
  Accordion,
} from "semantic-ui-react";


export default class AccordionExampleStyled extends Component {
  state = { activeIndex: 0 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Container>
        <h1>FAQ</h1>
        <Accordion styled>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            Do I have to unpack or pack the products?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <p>
            Our dedicated team will handle the setup and
maintenance, allowing you to relax and fully enjoy the
 festivities.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 1}
            index={1}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            When is pick up?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p>
              Pick-up depends on your reservation but it won't be later than 7 PM.
            </p>
          </Accordion.Content>

          <Accordion.Title
            active={activeIndex === 2}
            index={2}
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            What are your hours of operations?
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 2}>
            <p>
              7 AM to 5PM
            </p>
          </Accordion.Content>
        </Accordion>
      </Container>
    );
  }
}
