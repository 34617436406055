import React from "react";
// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";
//import './App.css';

import MenuBar from "./components/MenuBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import AboutUS from "./pages/AboutUS";
import FAQs from "./pages/FAQs";

//import Register from './pages/Register';

function App() {
  
  return (
    <Container style={{minWidth: "500px"}}>
      <MenuBar />
      <div>
        <Routes>
          <Route path="/Home" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Routes>
          <Route path="/Products" element={<Products />} />
        </Routes>
        <Routes>
          <Route path="/FAQs" element={<FAQs />} />
        </Routes>
        <Routes>
          <Route path="/AboutUs" element={<AboutUS />} />
        </Routes>
      </div>
      <Footer />
    </Container>
  );
}

export default App;
