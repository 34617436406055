import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Image,
  Menu,
} from "semantic-ui-react";

function MenuBar() {
  const pathname = window.location.pathname;

  const path = pathname === "/" ? "home" : pathname.substr(1);
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  return (
    <Menu pointing secondary size="massive" color="blue">
      <Menu.Item style={{ padding: 0 }}>
        <Image size="small" src="logo.png" style={{ width: "120px" }} />
      </Menu.Item>

      <Menu.Menu position="right">
        <Menu.Item
          name="home"
          active={activeItem === "home"}
          onClick={handleItemClick}
          as={Link}
          to="/home"
        />
        <Menu.Item
          name="Products"
          active={activeItem === "Products"}
          onClick={handleItemClick}
          as={Link}
          to="/Products"
        />
        <Menu.Item
          name="About Us"
          active={activeItem === "About Us"}
          onClick={handleItemClick}
          as={Link}
          to="/AboutUs"
        />
        <Menu.Item
          name="FAQ"
          active={activeItem === "FAQ"}
          onClick={handleItemClick}
          as={Link}
          to="/FAQs"
        />
      </Menu.Menu>
    </Menu>
  );
}

export default MenuBar;
