import React, { useState } from "react";
import { Button, Icon, Item } from "semantic-ui-react";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % images.length);
  };
  const handlePreviousClick = () => {
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div style={{ marginLeft: "auto", marginRight: "auto" }}>
      {images.map((image, index) => (
        <Item.Image
          key={index}
          size="large"
          rounded
          style={{ display: currentIndex === index ? "block" : "none" }}
        >
          <img
            src={image}
            style={{ height: "430px", width: "450px", objectFit: "cover" }}
            alt={` of the product`}
          />
        </Item.Image>
      ))}
      <p align="center" style={{ marginTop: "10px", marginBottom: "20px" }}>
        <button className="ui right icon button" onClick={handlePreviousClick}>
          <i className="left arrow icon"></i>
        </button>
        <button className="ui right icon button" onClick={handleNextClick}>
          <i className="right arrow icon"></i>
        </button>
      </p>
    </div>
  );
};

const ItemExampleFloated = () => {
  const set1Images = [
    "WhiteStoolsDesign.jpeg",
    "Prop1.jpg",
    "Prop2.jpg",
    "Prop3.jpg",
  ];
  const set2Images = [
    "SuperHeroBounce.jpeg",
    "BirthdayBanner.jpg",
    "FrozenBanner.jpg",
    "IncrediblesBanner.jpg",
    "MickeyBanner.jpg",
    "PawPatrolBanner.jpg",
    "PJBanner.jpg",
    "PrincessBanner.jpg",
    "SuperheroBanner.jpg",
    "TMNTBanner.jpg",
  ];
  const set4Images = ["SlipNSlide.jpeg", "FrontSlipNSlide.jpeg"];
  const set6Images = [
    "BubbleCageSide.jpeg",
    "BalloonDome1.jpg",
    "BalloonDome2.jpg",
    "BalloonDome3.jpg",
    "BalloonDome4.jpg",
  ];
  const set9Images = [
    "BuilderTable.jpeg",
    "GiftBasket.jpg",
    "Goodies.jpg",
    "GoodieTable.JPG",
  ];
  const set7Images = [
    "KidPark.jpeg",
    "KidParkFront.jpeg",
    "SoftColor.jpg",
    "SoftColor2.jpg",
    "PlayArea.jpeg",
    "PlayAreaFront.jpeg",
    "SoftBlue.jpg",
    "SoftBlue2.jpg",
  ];
  // const set8Images = [
  //   "PlayArea.jpeg",
  //   "PlayAreaFront.jpeg",
  //   "SoftBlue.jpg",
  //   "SoftBlue2.jpg",
  // ];
  const set3Images = ["WhiteCastle.JPG"];

  return (
    <div>
      <h1>Price List</h1>
      <p>
        *Prices are subject to taxes and delivery fee depending on distance. No
        dates will be reserved without a deposit, please keep in mind the
        deposit is nonrefundable but it can be used towards a future rental.*
      </p>
      <Item.Group relaxed>
        <Item>
          <ImageSlider images={set2Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>
              Traditional 13x13 Bounce House
            </Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              <p>
                Choose from one of our many character banners to make this
                bounce house customized to your party theme, starting at $160 + generator fee.
              </p>
            </Item.Description>

            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <ImageSlider images={set4Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>Waterslide</Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              <p>
                Make your party an unforgettable one with this thrilling and
                exciting attraction, available for rent for $295.
              </p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <ImageSlider images={set3Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>
              Toddler Bouncy
            </Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              <p>
                You can add the White Toddler Bounce Castle to your event for
                just $150.
              </p>
              <p>Available for children 1-5 years old.</p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>
        <Item>
          <ImageSlider images={set6Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>Balloon Dome</Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              <p>
                Balloon Dome only $450 this experience is 4 hrs and it includes
                attendant on site, 50 interior balloons
              </p>
              <p>Balloon Dome with seashell ball pit $495.</p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <ImageSlider images={set7Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>
              Colorful Toddler Soft Play Area
            </Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              <p>
                The perfect addition to your toddler's birthday party for just
                $395.
              </p>
              <p>Available for children 1-5 years old.</p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>

        {/* <Item>
          <ImageSlider images={set8Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>
              Blue Toddler Soft Play Area
            </Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
            <p>
                The perfect addition to your toddler's birthday party for just
                $395.
              </p>
              <p>Available for children 1-5 years old.</p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item> */}
        <Item>
          <ImageSlider images={set1Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>Props</Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              <p>Call for Pricing</p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <ImageSlider images={set9Images} />
          <Item.Content
            verticalAlign="middle"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "30px",
            }}
          >
            <Item.Header style={{ fontSize: "25px" }}>
              Entertainment Table
            </Item.Header>
            <Item.Description style={{ fontSize: "15px" }}>
              Introducing our customizable entertainment table, the ideal
              addition to any event!
              <p>Call for pricing</p>
            </Item.Description>
            <Item.Extra>
              <Button floated="right">
                Call 305-575-1835 to Book
                <Icon name="right arrow" />
              </Button>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </div>
  );
};

export default ItemExampleFloated;
