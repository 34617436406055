import React from "react";
import { Container, Header, Image } from "semantic-ui-react";

const FixedMenuLayout = () => (
  <div>
    <Container text style={{ marginTop: "2em" }}>
      <Header as="h1">Arango's Party Rental</Header>
      <p>
        This is a party rental company filled with products to take your events
        and parties to the next level.
      </p>
      {/* <p>
        A text container is used for the main container, which is useful for
        single column layouts.
      </p> */}
      <Image src="logo.png" style={{ marginTop: "2em" }} />
      <p>
        Welcome to Arango's Party Rental, a distinguished and cherished
        family-owned business that is passionately dedicated to delivering
        exceptional service to elevate your events to extraordinary heights. We
        take pride in our mission to ensure that every party becomes an
        unforgettable and awe-inspiring experience. At Arango's Party Rental, we
        offer a diverse range of premium products and services meticulously
        crafted to transform your event into an extraordinary celebration. From
        party rental equipment to props and exquisite decor, our extensive
        inventory has been thoughtfully curated to cater to your unique vision
        and style. We believe in fostering long-lasting relationships with our
        clients, built on trust, integrity, and a shared passion for creating
        exceptional experiences. Let Arango's Party Rental be your trusted
        partner. Together, we will transform your event into a remarkable
        affair, filled with enchantment, laughter, and cherished memories that
        will be treasured for a lifetime. Experience the difference of a
        family-owned business that wholeheartedly believes in making your
        celebrations truly extraordinary.
      </p>
    </Container>
  </div>
);

export default FixedMenuLayout;
