import React from "react";
import { Button, Container, Header, Icon, Image } from "semantic-ui-react";

const HomepageHeading = ({ mobile }) => (
  <Container
    style={{ width: "max-content", maxWidth: "max-content !important" }}
  >
    <Image
      src="background.jpg"
      style={{
        flex: 1,
        width: undefined,
        height: undefined,
        backgroundColor: "transparent",
        justifyContent: "center",
        alignItems: "center",
      }}
    />

    <Button
      primary
      size="huge"
      href="Products"
      style={{
        padding: 20,
        justifyContent: "center",
        flex: "auto",
        display: "flex",
        width: "255px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px",
      }}
    >
      Look at our Products
      <Icon name="right arrow" />
    </Button>

    <Container text style={{ marginTop: "3em" }}>
      <Header as="h1">
        <Image
          size="extra-large"
          src="logo.png"
          style={{ marginRight: "0.50em", marginTop: "-0.5em" }}
        />
        Arango's Party Rental
      </Header>
      <p>
        Welcome to our destination for extraordinary kids' birthday parties!
        Discover a wide range of exceptional products available for rent,
        ensuring each celebration becomes a cherished memory. From captivating
        inflatables to dazzling decor, we provide meticulously curated options
        for a truly unforgettable experience. Our dedicated team ensures no two
        birthdays are the same, offering personalization and attention to
        detail. With our trusted rentals, turn dreams into reality, creating an
        ambiance that reflects your child's vibrant personality. Step into a
        world where imagination takes flight and birthdays become extraordinary
        milestones. Unlock the full potential of your kids' parties with us!
      </p>

      {/* <Image src='/images/wireframe/media-paragraph.png' style={{ marginTop: '2em' }} /> */}
    </Container>
  </Container>
);

export default HomepageHeading;
